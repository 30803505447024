@import url("https://fonts.googleapis.com/css?family=Pacifico");
@import url("https://fonts.googleapis.com/css?family=Share+Tech+Mono");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro");
@import url("https://fonts.googleapis.com/css?family=Ubuntu");
html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: verdana;
    font-size: 18px;
    letter-spacing: 0.05rem;
    word-spacing: 0.05rem;
}
*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}
h1 {
    text-align: center;
}
.banner {
    border-radius: 15px;
    -webkit-box-shadow: 0;
    -moz-box-shadow: 0;
    box-shadow: 0;
    height: 100px;
    margin: 20px auto;
    padding: 20px;
    width: 620px;
}
.banner.banner0 {
    background-color: #416b6d;
    background-image: url("imgs/City-PNG-Transparent.png");
    background-position: bottom center;
    background-size: 50%;
}
.banner.banner1 {
    background-color: Moccasin;
    background-image: url("imgs/Sonya-Swarm-Coffee.256.png");
    background-position: center left;
    background-repeat: no-repeat;
    background-size: contain;
    color: Chocolate;
    font-family: 'Pacifico', cursive;
    padding-left: 100px;
    text-align: end;
}
.banner.banner1 h2 {
    line-height: 32px;
    font-weight: normal;
    -webkit-text-shadow: 0 0 0 transparent;
    -moz-text-shadow: 0 0 0 0 transparent;
    -ms-text-shadow: 0 0 0 0 transparent;
    -o-text-shadow: 0 0 0 0 transparent;
    text-shadow: 0 0 0 transparent;

}
.banner.banner2 {

    background-color: #a0bfa0;
    background-image: url("./imgs/00-preview.png");
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;
    color: #395439;
    font-family: 'Share Tech Mono', monospace;
    text-align: start;
    -webkit-text-shadow: -3px 3px 0px #76a376;
    -moz-text-shadow: 0 -3px 3px 0px #76a376;
    -ms-text-shadow: 0 -3px 3px 0px #76a376;
    -o-text-shadow: 0 -3px 3px 0px #76a376;
    text-shadow: -3px 3px 0px #76a376;
    padding-right: 150px;
}
.banner.banner3 {
    background-color: #228b22;
    background-image: url("imgs/Traction.png");
    background-position: 50px center;
    background-repeat: no-repeat;
    background-size: 15%;
    font-family: 'Source Sans Pro', sans-serif;
}
.banner.banner3 h2,
.banner.banner3 .slogan {
    text-align: right;
}
.banner.banner4 {
    background-color: #b22222;
    background-image: url("imgs/74401-200.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    font-family: 'Ubuntu', sans-serif;
}
.banner.banner4 h2 {
    float: left;
    text-transform: uppercase;
}
.banner.banner4 .slogan {
    float: right;
    text-transform: lowercase;
    width: min-content;
}
.banner.banner5 {
    background-color: #ffd700;
}
.banner.banner6 {
    background-color: #ff6347;
}
.banner.banner7 {
    background-color: #dc143c;
}
h2 {
    margin: 0;
    padding: 0;
    -webkit-text-shadow: 0 -1px 1px rgba(0,0,0,0.25);
    -moz-text-shadow: 0 0 -1px 1px rgba(0,0,0,0.25);
    -ms-text-shadow: 0 0 -1px 1px rgba(0,0,0,0.25);
    -o-text-shadow: 0 0 -1px 1px rgba(0,0,0,0.25);
    text-shadow: 0 -1px 1px rgba(0,0,0,0.25);
}
.slogan {
    font-size: 1.111em /*20px*/;
    margin: 0;
}


@media only screen and (max-width: 600px) {
    .banner {
        width: 100%; /* Full width on smaller screens */
        padding: 10px;
        margin: 10px 0;
    }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .banner {
        width: 80%; /* 80% width on medium screens */
        margin: 15px auto;
    }
}

/* Global style adjustments for responsiveness */
.banner {
    max-width: 620px; /* Maximum width */
    width: 100%; /* Default to full width */
    height: auto; /* Auto-adjust height */
    margin: 20px auto; /* Centering banners */
}

/* Responsive image settings */
.banner img {
    width: 100%;
    height: auto;
}

/* Responsive typography */
h1, h2, p {
    font-size: calc(1em + 1vw); /* Adjust font size based on viewport width */
}


.banner {
    height: 150px; /* Example fixed height, adjust as needed */
}
